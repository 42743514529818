<template>
   <div class="container-fluid">
       <div class="container">
           <b-row class=" no-gutters height-self-center">
               <b-col sm="12" class=" text-center align-self-center">
                   <div class="iq-error position-relative">
                       <img src="../../../assets/images/nodata.png" class="img-fluid iq-error-img" alt="">
                       <h2 class="mb-0">There are no fulfillments for this order.</h2>
                   </div>
               </b-col>
           </b-row>
       </div>
   </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
   name: 'InvoiceList',
   components: {
       
   },
   computed: {
       ...mapGetters({
           getInvoices: 'invoices/invoices'
       }),
       hasInvoices() {
           if (this.getInvoices) {
               return true
           } else {
               return false
           }
       },
       listInvoices() {
           return this.getInvoices
       }
   },
   beforeMount() {
       this.$store.dispatch('invoices/fetchInvoiceList', {
           orderId: this.$route.params.id
       });
   }
}
</script>